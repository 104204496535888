<template>
  <b-modal
    id="content-modal"
    ref="content-modal"
    title="Cập nhật tham số"
    size="xl"
  >
    <b-overlay :show="loading">
      <b-container fluid class="p-0">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col>
              <basic-input
                label="Mã tham số"
                placeholder="Mã tham số"
                name="code"
                :required="true"
                disabled
                :value.sync="form.key"
              ></basic-input>
            </b-col>
            <b-col>
              <basic-input
                label="Loại"
                placeholder="Mã tham số"
                name="group"
                :required="true"
                disabled
                :value.sync="form.group"
              ></basic-input>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <basic-input
                label="Tên nội dung"
                placeholder="Tên nội dung"
                name="name"
                :required="true"
                :value.sync="form.name"
                data-vv-as="Tên nội dung"
                v-validate="'required'"
                :state="validateState('name')"
                :invalidFeedback="errors.first('name')"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                label="Nội dung giới thiệu ngắn trên KPI"
                placeholder="Tên nội dung"
                name="shortDescription"
                :required="true"
                :value.sync="form.shortDescription"
                data-vv-as="Nội dung ngắn"
                v-validate="'required'"
                :state="validateState('shortDescription')"
                :invalidFeedback="errors.first('shortDescription')"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                label="Giá trị"
                placeholder="Giá trị"
                name="value"
                :required="true"
                :value.sync="form.value"
                data-vv-as="Giá trị"
                v-validate="'required'"
                :state="validateState('value')"
                :invalidFeedback="errors.first('value')"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-text-editors
                label="Nội dung giới thiệu đầy đủ khi người dùng xem chi tiết"
                placeholder="Nhập nội dung thông báo"
                name="content"
                :required="true"
                :value.sync="form.description"
                :toolbarOptions="['link', 'image', 'video']"
                data-vv-as="Nội dung chi tiết"
                v-validate="'required'"
                :state="validateState('content')"
                :invalidFeedback="errors.first('content')"
              >
              </basic-text-editors>
            </b-col>
          </b-row>
        </form>
      </b-container>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          @click.stop="handleCancel"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          href="#"
          @click.stop="handelValidation"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'Modal',
  components: {},
  props: {
    popupType: {
      type: String,
      default: 'detail',
    },
    id: {
      type: String,
      default: null,
    },
  },
  watch: {
    id: {
      deep: true,
      handler(newVal) {
        this.loadData(newVal);
      },
    },
  },
  data() {
    return {
      detail: {},
      validationState: {},
      error: {},
      form: {},
      loading: false,
    };
  },
  computed: {},
  methods: {
    async loadData(id) {
      this.loading = true;

      try {
        let { data } = await this.$api.get(`CommonConfigureTask/${id}`);
        this.form = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handelValidation() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.handleSubmit();
      });
    },
    async handleSubmit() {
      const keys = [
        'ShortGuide.BloodPressure.Content_1',
        'ShortGuide.BloodPressure.Content_2',
        'ShortGuide.Diet.Content_1',
        'ShortGuide.Diet.Content_2',
        'ShortGuide.Emotion.Content_1',
        'ShortGuide.Emotion.Content_2',
        'ShortGuide.Exercise.Content_1',
        'ShortGuide.Exercise.Content_2',
        'ShortGuide.Glucose.Content_1',
        'ShortGuide.Glucose.Content_2',
        'ShortGuide.HbA1c.Content_1',
        'ShortGuide.HbA1c.Content_2',
        'ShortGuide.Weight.Content_1',
        'ShortGuide.Weight.Content_2',
        'DiaB.Information.Security',
      ];
      if (keys.includes(this.form.key)) {
        this.form.value = this.form.description;
      }
      this.loading = true;

      try {
        await this.$api.put('CommonConfigureTask', this.form);
        this.$bvModal.hide('content-modal');
        this.$emit('loadData');
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$bvModal.hide('content-modal');
    },
  },
};
</script>

<style lang="scss">
#glucose-modal {
  .modal-dialog {
    width: 370px;
    height: 582px;
  }
}
</style>
